import React, { useState, useEffect, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components'; 
import Config from '../../styles/config';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { BodyWrapper, Header, HeaderItem } from '../../components/common/common';
import GridItem from '../../components/grid-item/grid-item';
import Grid from '../../components/grid/grid';
import Data from '../../data/data';
import BackArrow from '../../components/backarrow/BackArrow';
import AnimatedLink from '../../components/animated-link/AnimatedLink';
import useData from '../../data/useData';


const ClientItem = ({history}) => {

	const { slug } = useParams();
	
	const {clients, projects, loading} = useData();
	const [client, setClient] = useState();
	const [clientProjects, setClientProjects] = useState();


	useEffect(() => {
		if(!clients || !projects) return;
		const items = Data.getClient(slug, clients, projects);

		setClient(items.client);
		setClientProjects(items.projects);

	} , [clients, projects, slug]);


	
	const getClient = (item) => {
		return item && item.client ? item.client.title : '';
	}

	useLayoutEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 100);
	}, [])

	const hideItem = (item) => {
		if(!item) return true;
		if(item.hide_from_clients_page == 1 || item.hide_from_clients_page == true) return true;
		return false;
	}
	
	return (
		<>
			<Header>
				<AnimatedLink to="/clients">CLIENTS</AnimatedLink>
				{client && <HeaderItem lineHeight='1em' uppercase dangerouslySetInnerHTML={{__html: client.title}}></HeaderItem>}
				<BackArrow to="/clients" />
			</Header>
			<BodyWrapper>
				<Grid>

					{clientProjects && clientProjects.map((item, index) =>{
						if(hideItem(item)) return null;
						
						return <GridItem
							showHover
							index={index}
							key={item.slug}
							link={`/project/${item.slug}`}
							fromTitle={client.title}
							from={`/clients/${slug}`}
							title={getClient(item)}
							subtitle={item.title}
							cols={item.columns}
							rows={item.rows} 
							image={item.thumb}
							video={item.thumbVideo}
							videoWebM={item.thumbVideoWebM}
							disable_click={item.disable_click}
							/>
					})}
				</Grid>
				
			</BodyWrapper>
		</>
	);
};

export default withRouter(ClientItem);