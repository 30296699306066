import React, { useState, useEffect, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components'; 
import Config from '../../styles/config';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { BodyWrapper, Header, HeaderItem, TextContainer } from '../../components/common/common';
import { ProjectContainer, ProjectImage, ProjectVideo, Video, ProjectPiece, ProjectText, Youtube, ProjectSlide, ProjectDots, ProjectImageNoLink, ProjectImageLink, ProjectVideoEmbed, SliderNext, SliderPrev } from './project-styles';
import Heading from '../../components/heading/heading';
import Data from '../../data/data';
import BackArrow from '../../components/backarrow/BackArrow';
import AnimatedLink from '../../components/animated-link/AnimatedLink';
import { CarouselProvider, Slider, Slide, DotGroup, Dot, ButtonBack, ButtonNext } from 'pure-react-carousel';

// for Production this is added on the theme styles.css
import 'pure-react-carousel/dist/react-carousel.es.css';
import useData from '../../data/useData';



const exists = (data, prop) => {
	return (data && data[prop] && data[prop].length > 0);
}

const getPageTitle = (data) => {
	if(data.pageHeader && data.pageHeader.length > 0)
		return data.pageHeader;
	
	return data.title;
}


const fitRect = (rectW, rectH, boundsW, boundsH) => {
	const rectRatio = rectW / rectH;
	const boundsRatio = boundsW / boundsH;
  
	const rect = {};
  
	if(rectRatio > boundsRatio) {
		rect.width = boundsW;
		rect.height = rectH * (boundsW / rectW);
	} else {
		rect.width = rectW * (boundsH / rectH);
		rect.height = boundsH;
	}
  
	return rect;
  }


const Project = ({history, location}) => {
	const title = location && location.state && location.state.fromTitle ? location.state.fromTitle : 'us';
	const previous = location && location.state && location.state.from && location.state.from.trim().length > 0 ? location.state.from : '/us';
    // console.log("Project -> previous", previous)

	const ww = window.innerWidth;
	const wh = window.innerHeight;
	const headerH = wh * 0.10 < 170 ? 170 : wh * 0.10;
	
	const divWidth = ww * 0.80;
	const divHeight = wh - headerH - 50;

	const {slug} = useParams();
	const {projects} = useData();

	const [data, setData] = useState();
	const [mouseOver, setMouseOver] = useState(false);

	const [galleryW, setGalleryW] = useState(100);
	const [galleryH, setGalleryH] = useState(100);
	const [videoW, setVideoW] = useState(16);
	const [videoH, setVideoH] = useState(9);


	useEffect(() => {
		if(!projects || !slug) return;
		const data = Data.getProject(slug, projects);

		if(data.galleryW) setGalleryW(data.galleryW);
		if(data.galleryH) setGalleryH(data.galleryH);

		if(data && data.video && data.video.url){

			if(data && data.videoembed && data.videoembed.length > 4){
				setVideoW(divWidth);
				setVideoH(divWidth * 0.565);
			} else {
				setVideoW(data.video.width || 0);
				setVideoH(data.video.height || 0);
				// videoRatio = w && h ? h/w*100 : false;
			}
		}

		setData(data);

	}, [projects]);

	useLayoutEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 100);
	}, [])
	
	
	const videoSize = fitRect(videoW, videoH, divWidth, divHeight);

	const hasImage = exists(data, 'images');
	const hasVideo = exists(data, 'video');
	const hasEmbed = exists(data, 'videoembed');

	const ImageWrapper = exists(data, 'image_link') ? ProjectImageLink : ProjectImageNoLink;
	
	const isImage = (hasImage && !hasVideo && !hasEmbed);
	const isVideo = (!hasImage && hasVideo && !hasEmbed);
	const isEmbed = (!hasImage && !hasVideo && hasEmbed);

	const slideEnabled = isImage && data && data.images && data.images.length > 1;

	return (
		<>
			<Header>
				<AnimatedLink to={previous}>{title}</AnimatedLink>
				{data && <HeaderItem uppercase dangerouslySetInnerHTML={{__html: getPageTitle(data)}}></HeaderItem>}
				<BackArrow to={previous} />
			</Header>
			<BodyWrapper>
				<ProjectContainer>

					{data && data.description && data.description.length >= 2 &&
					<ProjectText>
						<Heading lineHeight='1.0em' align="left">{data.heading}</Heading>
						<TextContainer dangerouslySetInnerHTML={{__html: data.description}} />
					</ProjectText>}

					<ProjectPiece onMouseEnter={()=>{!Config.isMobile() && setMouseOver(true)}} onMouseLeave={()=>{!Config.isMobile() && setMouseOver(false)}}>
						{isImage &&
							<ImageWrapper
								href={data && data.image_link}
								target="_blank"
								>
								<CarouselProvider
									naturalSlideWidth={galleryW}
									naturalSlideHeight={galleryH}
									totalSlides={data.images.length}
									isPlaying={!mouseOver && slideEnabled && !Config.isMobile()}
									dragEnabled={slideEnabled}
									touchEnabled={slideEnabled}
									interval={3000}
								>
									<Slider>
										{data.images.map((item, index) => (
											<Slide key={item+index} index={index}>
												<ProjectSlide>
													<ProjectImage src={item} alt={data.title} />
												</ProjectSlide>
											</Slide>
										))}
									</Slider>
									{slideEnabled && <ProjectDots>
										<DotGroup />
									</ProjectDots>}
									{slideEnabled && <SliderNext><ButtonNext>
										<svg xmlns="http://www.w3.org/2000/svg" width="15.659" height="24.846" viewBox="0 0 15.659 24.846">
											<path data-name="Path 3" d="M12.423 0L0 12.423l12.423 12.423 3.236-3.236-9.187-9.187 9.187-9.187L12.423 0z" fill="#e1e1e1"/>
										</svg>	
									</ButtonNext></SliderNext>}
									{slideEnabled && <SliderPrev><ButtonBack>
										<svg xmlns="http://www.w3.org/2000/svg" width="15.659" height="24.846" viewBox="0 0 15.659 24.846">
											<path data-name="Path 3" d="M12.423 0L0 12.423l12.423 12.423 3.236-3.236-9.187-9.187 9.187-9.187L12.423 0z" fill="#e1e1e1"/>
										</svg>	
									</ButtonBack></SliderPrev>}
									
								</CarouselProvider>
							</ImageWrapper>
							
						}

						{isVideo && 
							<ProjectVideo>
								<ProjectVideoEmbed videoWidth={`${videoSize.width}px`} videoHeight={`${videoSize.height}px`}>
									<Video width="100%" height="100%" controls={true} autoPlay={false}>
										<source src={data.video.url} />
									</Video>
								</ProjectVideoEmbed>
							</ProjectVideo>
						}
						
						{isEmbed && 
							<ProjectVideo>
								<ProjectVideoEmbed videoWidth={`${videoSize.width}px`} videoHeight={`${videoSize.height}px`}>
									<Youtube dangerouslySetInnerHTML={{__html: data.videoembed}} />
								</ProjectVideoEmbed>
							</ProjectVideo>
						}


					</ProjectPiece>

				</ProjectContainer>
			</BodyWrapper>
		</>
	);
};

export default withRouter(Project);