import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { motion } from 'framer-motion';
import Config from '../../styles/config';

//const Container = styled(Link)`
const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 3px;
	overflow: hidden;
	background-color: ${Config.DARK};
	opacity: 0.5;
`;

const Inner = styled(motion.div)`
	width: 100px;
	height: 3px;
	background-color: ${Config.TEXT};
`;


const animate = {
	x: '100vw',
}


const transition = {
	type: 'tween',
	duration: 1.45,
	ease: "easeInOut",
	repeat: Infinity,
	repeatType: "reverse"
}

const Loading = ({}) => {

	return (
		<Container>
			<Inner
				transition={transition}
				animate={animate}
			/>
		</Container>
	);
};

export default Loading;