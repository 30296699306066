import React from "react";

const LogoTop = props => (
  <svg x="0px" y="0px" viewBox="0 0 410.2 119.1" xmlSpace="preserve" {...props}>
    <path d="M219 25.6L231.8 25.6 231.8 0 180.7 0 180.7 25.6 193.5 25.6 193.5 47.7 219 47.7z" />
    <path d="M272.5 0L246.1 0 246.1 47.7 294.1 47.7z" />
    <path d="M116.6 25.6h25.6v22.1h25.6V21c0-14.7-6-21-19.6-21h-37.5C97 0 91.1 6.3 91.1 21v26.7h25.4V25.6z" />
    <path d="M297.2 0H322.8V47.7H297.2z" />
    <path d="M410.2 25.6L410.2 0 333.6 0 333.6 25.6 359.2 25.6 359.2 47.7 384.8 47.7 384.8 25.6z" />
    <path d="M76.6 0L29.7 0 29.7 25.6 51 25.6 51 47.7 76.6 47.7z" />
  </svg>
);

export default LogoTop;
