import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { BodyStyles } from './styles/styles';
import Home from './pages/home/home';
import Work from './pages/work/work';
import WorkCategory from './pages/work-category/work-category';
import Clients from './pages/clients/clients';
import ClientItem from './pages/client-item/client-item';
import Project from './pages/project/project';
import useData from './data/useData';
import Loading from './components/loading/loading';

const App = (props) => {

	const {loading} = useData();
    // console.log(`🚀 ~ file: App.js ~ line 15 ~ App ~ loading`, loading);

	return (
		<>
			<BodyStyles />
			<Router>
				<Switch>
					<Route exact path={['/', '/contact', '/us']}>
						<Home />
					</Route>
					<Route exact path="/work">
						<Work />
					</Route>
					<Route path="/work/:slug">
						<WorkCategory />
					</Route>
					<Route exact path="/clients">
						<Clients />
					</Route>
					<Route path="/clients/:slug">
						<ClientItem />
					</Route>
					<Route path="/project/:slug">
						<Project />
					</Route>
				</Switch>
			</Router>
			{loading && <Loading />}
		</>
	)
}



export default App;