import { useState, useEffect, useRef } from 'react';
import useFetch from 'use-http';
import { API } from './data';

function useData() {

	const { get, post, response, loading, error, cache } = useFetch(API);
	
	const [options, setOptions] = useState();
	const [work, setWork] = useState();
	const [clients, setClients] = useState();
	const [projects, setProjects] = useState();

	async function loadWork() {
		//?page=1&per_page=1000
		//?post_type=work&page=1&per_page=1000
		// cache.clear();
		const res = await get('?page=1&per_page=1200');
		// console.log(res)
		if (res && response.ok){
			setWork(res.work);
			setProjects(res.projects);
			setClients(res.clients);
			setOptions(res.options);
		}
	}

	useEffect(() => {
		loadWork();
	} , [])

	return {work, clients, projects, options, loading, error};
}

export default useData;
