import React, { useState, useEffect, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components'; 
import Config from '../../styles/config';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { BodyWrapper, Header, HeaderItem } from '../../components/common/common';
import GridItem from '../../components/grid-item/grid-item';
import Grid from '../../components/grid/grid';
import Data from '../../data/data';
import BackArrow from '../../components/backarrow/BackArrow';
import AnimatedLink from '../../components/animated-link/AnimatedLink';
import useData from '../../data/useData';


const WorkCategory = ({history}) => {
	const { slug } = useParams();
	//console.log(slug);
	//console.log(Data.getData());

	const {work, projects, loading} = useData();

	const [category, setCategory] = useState();
	const [workProjects, setWorkProjects] = useState();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 30);
	} , [])
	
	useEffect(() => {
		if(!work || !projects) return;
		// console.log('work :>> ', work);
		// console.log('projects :>> ', projects);
		const items = Data.getWorkCategory(slug, work, projects);
		// console.log(items);
		setCategory(items.category);
		setWorkProjects(items.projects);

	} , [work, projects, slug]);
	
	const getClient = (item) => {
		return item && item.client ? item.client.title : '';
	}

	const disableClick = (category, item, title) => {
		if(!item) return true;
		let dc = false;
		if(category.disable_click == true || category.disable_click == 1 || item.disable_click == true || item.disable_click == 1) {
			dc = true;
		}
		
		// console.log(category.disable_click, item.disable_click, dc, '    |    ', title);
		return dc;
	}

	
	return (
		<>
			<Header>
				<AnimatedLink to="/work">WORK</AnimatedLink>
				{category && <HeaderItem lineHeight='1em' uppercase dangerouslySetInnerHTML={{__html: category.title}}></HeaderItem>}
				<BackArrow to="/work" />
			</Header>
			<BodyWrapper>
				<Grid disable_click={category && category.disable_click}>
					{workProjects && workProjects.map((item, index) => {
                    	
						if(item.hide_from_work_page === true){
							return null;
						}

						// const disable_click = category && category.disable_click ? Boolean(category.disable_click || item.disable_click) : false;
						const disable_click = disableClick(category, item, getClient(item));

						return <GridItem
							fixedTitles={category.fixed_titles}
							showHover
							index={index}
							key={`${item.slug}_${index}`}
							link={`/project/${item.slug}`}
							fromTitle={category.title}
							from={`/work/${slug}`}
							title={getClient(item)}
							subtitle={item.title}
							cols={item.work.columns}
							rows={item.work.rows}
							image={item.thumb}
							imageHover={item.thumbHover}
							video={item.thumbVideo}
							disable_click={disable_click}
							/>
					})}
				</Grid>
				
			</BodyWrapper>
		</>
	);
};

export default withRouter(WorkCategory);