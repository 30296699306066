import React from "react";

const LogoBottom = props => (
  <svg x="0px" y="0px" viewBox="0 0 410.2 119.1" xmlSpace="preserve" {...props}>
    <path d="M51 93.5H25.6v-17H0v21.8c0 14.6 5.8 20.8 19.2 20.8h38.3c13.3 0 19.2-6.3 19.2-20.8V68.8H51v24.7z" />
    <path d="M297.2 119.1L322.8 119.1 322.8 68.8 274.3 68.8z" />
    <path d="M193.5 93.5L180.7 93.5 180.7 119.1 231.8 119.1 231.8 93.5 219 93.5 219 68.8 193.5 68.8z" />
    <path d="M246.1 68.8H271.5V119.1H246.1z" />
    <path d="M359.2 68.8H384.8V119.1H359.2z" />
    <path d="M142.2 93.5h-25.6V68.8H91.1v29.4c0 14.6 5.8 20.8 19.5 20.8h37.5c13.6 0 19.6-6.3 19.6-20.8V68.8h-25.6v24.7z" />
  </svg>
);

export default LogoBottom;
