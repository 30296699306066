import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components'; 
import Config from '../../styles/config';
//import { Logo } from './Logo';
import LogoBottom from './LogoBottom';
import LogoTop from './LogoTop';

const ww = window.innerWidth || document.documentElement.clientWidth;
const wh = window.innerHeight || document.documentElement.clientHeight;
const halfH = wh/2;

const size = Config.isMobile() ? 220 : 500;
const offset = Config.isMobile() ? '-31px' : '-72px';

const ContainerTop = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 50.1%;
	background-color: ${Config.TEXT};
`;

const ContainerBottom = styled(motion.div)`
	position: absolute;
	top: 50%;
	left: 0;
	width: 100vw;
	height: 50%;
	background-color: ${Config.TEXT};
`;

const LogoHolderTop = styled.div`
	position: absolute;
	bottom: ${offset};
	left: 50%;
	transform: translateX(-50%);
`;

const LogoHolderBottom = styled.div`
	position: absolute;
	top: ${offset};
	left: 50%;
	transform: translateX(-50%);
`;

const varTop = {
	visible: { y: 0 },
	hidden: { y: -halfH, transitionEnd:{ display: "none"} },
}

const varBottom = {
	visible: { y: 0 },
	hidden: { y: halfH, transitionEnd:{ display: "none"} },
}

const transition = {
	type: 'tween',
	duration: 0.850,
	ease: 'easeInOut',
	delay: 1,
	
}


const AnimatedLogo = () => {
	return (
		<>
			<ContainerTop
				variants={varTop}
				initial='visible'
				transition={transition}
				animate='hidden'
			>
				<LogoHolderTop>
					<LogoTop width={size} />
				</LogoHolderTop>
			</ContainerTop>
			<ContainerBottom
				variants={varBottom}
				initial='visible'
				transition={transition}
				animate='hidden'
			>
				<LogoHolderBottom>
					<LogoBottom width={size} />
				</LogoHolderBottom>
			</ContainerBottom>

			{/* <LogoHolder>
				<Logo width={500} />
			</LogoHolder> */}
		</>
	);
};

export default AnimatedLogo;