import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Config from '../../styles/config';
import { motion } from 'framer-motion';
import breakpoint from 'styled-components-breakpoint';


export const ProjectContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	
	${breakpoint('tablet')`
		flex-direction: row;
	`}
`;

export const ProjectText = styled.div`
	flex: 1;
	order: 2;

	margin-left: 0;
	margin-right: 0;
	padding: 15px;
	
	${breakpoint('tablet')`
		order: 1;
		padding: 0;
		margin-right: 48px;
	`}
`;

export const ProjectPiece = styled.div`
	flex: 1;
	order: 1;
	margin-bottom: ${Config.GRID_GAP};

	${breakpoint('tablet')`
		margin-bottom: 0;
		order: 2;
	`}
`;


export const ProjectImageLink = styled.a`
	display: block;
	position: relative;
`;

export const ProjectImageNoLink = styled.div`
`;

export const ProjectImage = styled.img`
	width: 100%;
	max-width: 100%;
`;

export const ProjectSlide = styled.div`
	width: 100%;
	padding: 1px;
`;

export const ProjectDots = styled.div`
	width: 100%;
	padding: 15px;
	text-align: center;

	.carousel__dot{
		display: inline-block;
		-webkit-appearance: none;
		width: 10px;
		height: 10px;
		padding: 0;
		margin-left: 3px; 
		margin-right: 3px; 
		border-radius: 5px;
		border: none;
		background-color: ${Config.TEXT};
		opacity: 0.25;
		outline: none;

		:focus{
			outline: none;
		}

		&--selected{
			opacity: 1;
		}
	}
`;


export const SliderNext = styled.div`
	display: none;
	position: absolute;
	top: 50%;
	right: -40px;
	margin-top: -40px;

	button{
		background-color: transparent;
		background: transparent;
		outline: none;
		border: none;
	}

	svg{
		transform: scaleX(-1);
	}

	${breakpoint('tablet')`
		display: block;
	`}
`;

export const SliderPrev = styled.div`
	display: none;
	position: absolute;
	top: 50%;
	left: -40px;
	margin-top: -40px;

	button{
		background-color: transparent;
		background: transparent;
		outline: none;
		border: none;
	}

	${breakpoint('tablet')`
		display: block;
	`}
`;

export const ProjectVideo = styled.div`
	/* background-color: red; */
	width: 100%;
	height:  ${props => `${props.height}`};
	/* height: calc(70vh); */
	/* padding-top: ${props => `${props.ratio}vw`}; */
`;

ProjectVideo.defaultProps = {
	// height: 'calc(100vh - 250px)',
	height: 'calc(100vh - max(10vw, 170px) - 50px)',
}

export const ProjectVideoEmbed = styled.div`
	/* background-color: yellow; */
	margin: 0 auto;
	width: ${props => `${props.videoWidth}`};
	height: ${props => `${props.videoHeight}`};
	/* height: calc(70vh); */
	/* padding-top: ${props => `${props.ratio}vw`}; */
`;

ProjectVideoEmbed.defaultProps = {
	// height: 'calc(100vh - 250px)',
	videoWidth: '100%',
	videoHeight: 'calc(56vw - 10vw)',
}

export const Video = styled.video`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Youtube = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	iframe {
		width: 100% !important;
		height: 100% !important;
	}
`;