import Config from "../styles/config";


// import json from './local';
// if(window && window.location.hostname === 'localhost'){
// 	window.options = {
// 		contact_email: "mailto:everyone@jointlondon.com",
// 		contact_facebook: "https://www.facebook.com/jointlondon/",
// 		contact_instagram: "https://twitter.com/jointlondon",
// 		contact_linkedin: "https://twitter.com/jointlondon",
// 		contact_twitter: "https://twitter.com/jointlondon",
// 		favicon: "http://jointlondon:8888/wp-content/uploads/2020/02/favicon.png",
// 		map_image: "http://jointlondon:8888/wp-content/uploads/2020/04/london-map.jpg",
// 		map_link:
// 			"https://www.google.co.uk/maps/place/30+Market+Pl,+Fitzrovia,+London+W1W/@51.5158263,-0.1402191,19z/data=!3m1!4b1!4m2!3m1!1s0x48761b2ab973bb49:0xbf613195b665bd10",
// 	};
	
// 	window.data = json;
// }

const PATH = "/wp-json/custom_types/latest-posts";
let url = PATH;
if(window && window.location.hostname === 'localhost'){
	url = 'http://joint-wp-local:8888/wp-json/custom_types/latest-posts';
}

export const API = url;
// export const API = "http://joint-wp-local:8888/wp-json/custom_types/latest-posts"; //?post_type=clients&page=1&per_page=20";
// export const API = "/wp-json/custom_types/latest-posts"; //?post_type=clients&page=1&per_page=20";

export default class Data {

	// static data = window.data;
	// static work = window.data.work;
	// static clients = window.data.clients;
   	// static projects = window.data.projects;

	// static getWork() {
	// 	//console.log(this.work);
	// 	if(Config.isMobile()){
	// 		for (let i = 0; i < this.work.length; i++) {
	// 			const large = i % 3 === 0;
	// 			this.work[i].columns = this.work[i].columns_mobile; //Math.round(this.work[i].columns / 2).toString(); // large ? '2' : '1';
	// 			this.work[i].rows = this.work[i].rows_mobile; //Math.round(this.work[i].rows / 2).toString(); // '1';
	// 		}
	// 	}
		
	// 	return this.work;
	// }
	
	static getClients() {
		// console.log("Data -> clients", this.clients)
		if(Config.isMobile()){
			for (let i = 0; i < this.clients.length; i++) {
				this.clients[i].columns = this.clients[i].columns_mobile; //Math.round(this.clients[i].columns / 2).toString(); // large ? '2' : '1';
				this.clients[i].rows = this.clients[i].rows_mobile; //Math.round(this.clients[i].rows / 2).toString(); // '1';
			}
		}

		// console.log(this.clients)

		return this.clients;
	}
	
	static getProject(slug, _projects) {
		let item = _projects.filter(item => item.slug.toLowerCase() === slug.toLowerCase());
		if(!item || item.length <= 0) return [];
		const project = item[0];

		return project;
	}
	
	
	static getWorkCategory(slug, _work, _projects) {
		// console.log(slug);
		// console.log(_work);
		// console.log(_projects);
		let item = _work.filter(item => item.slug.toLowerCase() === slug.toLowerCase());
		if(!item || item.length <= 0) return [];
		const category = item[0];
        // console.log("Data -> getWorkCategory -> category", category)

		//console.log(this.projects);
		//let projects = this.projects.filter(item => item.work.slug.toLowerCase() === slug.toLowerCase());
		let projects = [];
		for (let i = 0; i < _projects.length; i++) {
			const project = _projects[i];
			// if(!project.work || !project.work.slug){
			// 	continue;
			// }
			// if(project.work.slug.toLowerCase() === slug.toLowerCase()){
			// 	console.log(project)
			// 	projects.push( project );
			// }
			for (let j = 0; j < project.work.slug.length; j++) {
				//console.log(project.work)
				const itemWork = project.work.slug[j];
                // console.log("Data -> getWorkCategory -> item", item)
				if(itemWork && itemWork.length >0 && itemWork.toLowerCase() === slug.toLowerCase()){
					projects.push( project );
				}
			}
		}
		
		if(Config.isMobile()){
			for (let i = 0; i < projects.length; i++) {
				const item = projects[i].work;
				item.columns = item.columns_mobile;//Math.round(item.columns / 2).toString(); // large ? '2' : '1';
				item.rows = item.rows_mobile;//Math.round(item.rows / 2).toString(); // '1';
			}
		}


		return {category, projects};
	}
	
	
	static getClient(slug, _clients, _projects) {
		if(!slug || slug.length <= 0) return [];
		let item = _clients.filter(item => item.slug.toLowerCase() === slug.toLowerCase());
        // console.log("Data -> getClient -> window.data.clients", window.data.clients)
		if(!item || item.length <= 0) return [];
		const client = item[0];
		
        const projects = [];
		for (let i = 0; i < _projects.length; i++) {
			const item = _projects[i];
			if(item && item.client && item.client.slug){
                if(item.client.slug === slug){
					
					if(Config.isMobile()){
						item.rows = item.client.rows_mobile;
						item.columns = item.client.columns_mobile;
					} else {
						item.rows = item.client.rows;
						item.columns = item.client.columns;
					}
					// console.log("Data -> getClient -> item", item)
					projects.push(item);
				}
			}
		}

		// console.log(projects)
		return {client, projects};
	}

	static getData() {
		return this.data;
	}

}