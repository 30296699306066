import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from 'styled-components';

import App from './App';
import Config from './styles/config';

const title = '';
ReactDOM.render( <App title={title} />, document.getElementById('app') );

